exports.components = {
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-benefits-of-cloud-computing-for-modern-businesses-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/benefits-of-cloud-computing-for-modern-businesses.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-benefits-of-cloud-computing-for-modern-businesses-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-best-practices-for-it-infrastructure-in-2024-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/best-practices-for-it-infrastructure-in-2024.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-best-practices-for-it-infrastructure-in-2024-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-cost-saving-tips-with-cloud-it-solutions-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/cost-saving-tips-with-cloud-it-solutions.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-cost-saving-tips-with-cloud-it-solutions-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-enhancing-cybersecurity-with-managed-it-services-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/enhancing-cybersecurity-with-managed-it-services.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-enhancing-cybersecurity-with-managed-it-services-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-google-cloud-vs-aws-choosing-the-best-for-your-business-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/google-cloud-vs-aws-choosing-the-best-for-your-business.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-google-cloud-vs-aws-choosing-the-best-for-your-business-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-choose-the-right-cloud-service-provider-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/how-to-choose-the-right-cloud-service-provider.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-choose-the-right-cloud-service-provider-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-leveraging-aws-for-scalable-business-solutions-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/leveraging-aws-for-scalable-business-solutions.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-leveraging-aws-for-scalable-business-solutions-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-and-accessibility-explore-how-technology-is-breaking-barriers-for-people-with-disabilities-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/tech-and-accessibility-explore-how-technology-is-breaking-barriers-for-people-with-disabilities.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-and-accessibility-explore-how-technology-is-breaking-barriers-for-people-with-disabilities-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-future-of-it-services-trends-to-watch-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-future-of-it-services-trends-to-watch.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-future-of-it-services-trends-to-watch-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-role-of-ai-in-it-service-management-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-role-of-ai-in-it-service-management.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-role-of-ai-in-it-service-management-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tips-for-effective-it-asset-management-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/tips-for-effective-it-asset-management.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tips-for-effective-it-asset-management-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-top-strategies-for-digital-transformation-with-it-services-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/top-strategies-for-digital-transformation-with-it-services.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-top-strategies-for-digital-transformation-with-it-services-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-understanding-domain-driven-development-ddd-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/understanding-domain-driven-development-ddd.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-understanding-domain-driven-development-ddd-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-wearable-tech-discuss-the-evolution-and-future-of-wearable-technology-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/wearable-tech-discuss-the-evolution-and-future-of-wearable-technology.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-wearable-tech-discuss-the-evolution-and-future-of-wearable-technology-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-why-it-is-crucial-to-have-a-performant-and-accessible-company-webpage-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/why-it-is-crucial-to-have-a-performant-and-accessible-company-webpage.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-why-it-is-crucial-to-have-a-performant-and-accessible-company-webpage-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-why-you-should-be-using-competency-based-questions-during-the-hiring-process-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/why-you-should-be-using-competency-based-questions-during-the-hiring-process.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-why-you-should-be-using-competency-based-questions-during-the-hiring-process-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-job-offers-tsx": () => import("./../../../src/pages/job-offers.tsx" /* webpackChunkName: "component---src-pages-job-offers-tsx" */),
  "component---src-pages-offer-application-development-tsx": () => import("./../../../src/pages/offer/application-development.tsx" /* webpackChunkName: "component---src-pages-offer-application-development-tsx" */),
  "component---src-pages-offer-cloud-consulting-tsx": () => import("./../../../src/pages/offer/cloud-consulting.tsx" /* webpackChunkName: "component---src-pages-offer-cloud-consulting-tsx" */),
  "component---src-pages-offer-digital-transformation-tsx": () => import("./../../../src/pages/offer/digital-transformation.tsx" /* webpackChunkName: "component---src-pages-offer-digital-transformation-tsx" */),
  "component---src-pages-offer-engineering-team-management-tsx": () => import("./../../../src/pages/offer/engineering-team-management.tsx" /* webpackChunkName: "component---src-pages-offer-engineering-team-management-tsx" */),
  "component---src-pages-offer-it-recruitment-tsx": () => import("./../../../src/pages/offer/it-recruitment.tsx" /* webpackChunkName: "component---src-pages-offer-it-recruitment-tsx" */),
  "component---src-pages-offer-it-trainings-tsx": () => import("./../../../src/pages/offer/it-trainings.tsx" /* webpackChunkName: "component---src-pages-offer-it-trainings-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---templates-blog-list-template-tsx": () => import("./../../../templates/BlogListTemplate.tsx" /* webpackChunkName: "component---templates-blog-list-template-tsx" */)
}

